<template>

<!-- 校园筛查 校园管理 学校学生导入 -->
  <!--  批量导入学生页面 -->

  <div class="page">
    <div class="header">
      <div class="header-button">
        <a style="text-decoration: underline" href="https://ks3-cn-beijing.ksyun.com/magic3d/test/document/export.xlsx">下载Excel模板</a>
        <router-link :to="{name: 'managementStudentManagement'}">
          <el-button>
          返回
        </el-button>
        </router-link>
      </div>
    </div>
    <div class="tips">
      <h2 style="font-weight: bold">批量导入学生用来批量填写新增的学生，按界面图示步骤上传学生列表。</h2>
      <h2>
        注：每个学生请确定对应好的学校/班级/年级。学校无法通过导入创建，需在「筛查前设置」 -> 「学校管理」内创建对应的学校，<br>
        并在表里写好对应学校名称，系统会自动关联至对应学校下。班级和年级可通过批量导入或新增创建。</h2>
    </div>

    <div class="steps">
      <header>
        <h1 style="font-weight: bold">步骤一：按图中所示编辑好excel文件待传</h1>
        <div class="pic">
          <el-image
            style="width: 100%;border: 1px dashed #CCCCCC;"
            :src="require('@/assets/management/ImportStudent1.png')"
            :preview-src-list="[require('@/assets/management/ImportStudent1.png')]"
            z-index.number="0">
          </el-image>
        </div>
      </header>
      <div class="stepsText">
        <span style="font-size: 15px;">打<span style="color: red">*</span>的项目为必填项</span>
      </div>

      <div class="prompt">
        <h2 style="font-size: 16px;font-weight: 700;">导入格式 例：</h2>
        <div class="pic">
          <el-image
            style="width: 100%;border: 1px dashed #CCCCCC;"
            :src="require('@/assets/management/ImportStudent2.png')"
            :preview-src-list="[require('@/assets/management/ImportStudent2.png')]"
            z-index.number="1"
          >
          </el-image>
        </div>
      </div>
    </div>
    <div class="stepsTwo">
      <header>
        <h1 style="font-weight: bold">步骤二：上传excel文件预览要导入的名单</h1>
        <el-upload
          ref="upload"
          name="file"
          class="upload-demo"
          drag
          action="/"
          :limit="limit"
          :on-exceed="handleExceed"
          :on-remove="removeFile"
          :on-change="fileChange"
          :file-list="fileList"
          :auto-upload="false"
        >
          <i class="uploadIcon"></i>
          <div class="el-upload__text">点击或拖到该区域上传
            <br>
            <em style="color:red">请确保是按步骤一模板填写并填写正确</em>
          </div>
          <div class="el-upload__tip" slot="tip" :style="styleObject">
            <i v-show="hidenUpload"></i>
          </div>

        </el-upload>
      </header>
    </div>
    <div class="footer">
      <el-button
        type="primary"
        size="mini"
        round
        v-loading.fullscreen.lock="fullscreenLoading"
        @click="submitUpload"
      >
        确认导入
      </el-button>
    </div>
    <el-table :data="xlsxJsonData"  style="width: 100%;" :row-class-name="tableRowClassName" v-show="xlsxJsonData.length>0">
      <el-table-column prop="__EMPTY" label="学校*" width="160">
      </el-table-column>
      <el-table-column prop="__EMPTY_1" label="年级*" align="center" width="100">
      </el-table-column>
      <el-table-column prop="__EMPTY_2" label="班级*" align="center" width="100">
      </el-table-column>
      <el-table-column prop="__EMPTY_3" label="姓名*" align="center" width="100">
      </el-table-column>
      <el-table-column prop="__EMPTY_4" label="性别*" align="center" width="80">
      </el-table-column>
      <el-table-column prop="__EMPTY_5" label="民族" align="center" width="100">
      </el-table-column>
      <el-table-column prop="__EMPTY_6" label="学号" align="center" width="100">
      </el-table-column>
      <el-table-column prop="__EMPTY_7" label="出生日期" width="120" align="center">
      </el-table-column>
      <el-table-column prop="__EMPTY_8" label="身份证号" width="160" align="center">
      </el-table-column>
      <el-table-column prop="__EMPTY_9" label="手机号码*" width="120" align="center">
      </el-table-column>
      <el-table-column prop="__EMPTY_10" label="视力检查日期" width="120" align="center">
      </el-table-column>
      <el-table-column prop="__EMPTY_11" label="戴镜类型" align="center">
      </el-table-column>
      <el-table-column prop="右眼" label="裸眼右" align="center">
      </el-table-column>
      <el-table-column prop="左眼" label="裸眼左" align="center">
      </el-table-column>
      <el-table-column prop="右眼_1" label="戴镜右" align="center">
      </el-table-column>
      <el-table-column prop="左眼_1" label="戴镜左" align="center">
      </el-table-column>
      <el-table-column prop="__EMPTY_12" label="屈光检查日期" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼球镜DS" label="右眼球镜DS" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼柱镜DC" label="右眼柱镜DC" width="120" align="center">
      </el-table-column>
      <el-table-column prop="右眼轴位Axis" label="右眼轴位Axis" width="120" align="center">
      </el-table-column>
      <el-table-column prop="左眼球镜DS" label="左眼球镜DS" width="120" align="center">
      </el-table-column>
      <el-table-column prop="左眼柱镜DC" label="左眼柱镜DC" width="120" align="center">
      </el-table-column>
      <el-table-column prop="左眼轴位Axis" label="左眼轴位Axis" width="120" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import StudentsService from '@/globals/service/detection/Students.js'

export default {
  data () {
    return {
      fullscreenLoading: false,
      styleObject: {},
      fileName: '',
      file: '',
      limit: 1,
      hidenUpload: false,
      confirmToImport: 0,
      errmesg: [],
      fileList: [],
      xlsxJsonData: [],
      reviewForm: ''
    }
  },
  mounted () {

  },
  methods: {
    goTo (path) {
      this.$router.push({ path: path })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'single-row'
      } else {
        return 'double-row'
      }
    },
    handleExceed (e) {
      // 判断是否只能上传一个文件，超过提示报错
      this.$notify.error({
        title: '错误',
        message: '只能上传一个文件哦'
      })
    },
    fileChange (file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$notify.error({
          title: '错误',
          message: '上传文件只能为excel文件，且为xlsx,xls格式'
        })
        this.fileList = []
        this.file = ''
        return false
      }

      const isLtSize = file.size / 1024 / 1024 < 10
      if (!isLtSize) {
        this.$notify.error({
          title: '错误',
          message: '上传文件大小不能超过 10MB!'
        })
        this.fileList = []
        this.file = ''
        return false
      }
      this.file = file.raw
      this.fileName = file.name
      this.importExcel(fileList[0])
    },
    // 选择/移除文件时触发
    removeFile (file, fileList) {
      this.fileList = []
      this.fileName = ''
      this.file = ''
      this.xlsxJsonData = []
    },
    // 导入
    importExcel (file) {
      const types = file.name.split('.')[1]
      const fileType = ['xlsx', 'xls'].some(
        item => item === types
      )
      if (!fileType) {
        alert('格式错误！请下载模板编辑后上传')
        return
      }
      this.file2Xce(file).then(tabJson => {
        if (tabJson && tabJson.length > 0) {
          this.xlsxJsonData = tabJson[0].sheet
        }
      })
    },
    file2Xce (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        reader.onload = function (e) {
          const data = e.target.result
          this.wb = XLSX.read(data, {
            type: 'binary'
          })
          const result = []
          // const xlsxLth = this.wb.Sheets[this.wb.SheetNames[0]]['!ref']
          // let stopX = xlsxLth.substr(xlsxLth.indexOf(':')+1,this.wb.Sheets[this.wb.SheetNames[0]]["!ref"].length);
          const stopX = 'V22'
          this.wb.Sheets[this.wb.SheetNames[0]]['!ref'] = 'A2:' + stopX
          this.wb.SheetNames.forEach(sheetName => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            })
          })
          resolve(result)
        }
        reader.readAsBinaryString(file.raw)
      })
    },
    // 导入 end
    // 点击按钮上传
    submitUpload () {
      const that = this
      if (this.file === '') {
        that.$notify.error({
          title: '错误',
          message: '上传文件不能为空'
        })
        return false
      }
      that.fullscreenLoading = true
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('uid', window.sessionStorage.getItem('uid'))
      formData.append('loginKey', window.sessionStorage.getItem('loginKey'))

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
      console.log('formData', formData)
      console.log('this.file', this.file)
      StudentsService.import(formData, config)
        .then(res => {
          const failureList = res.data.failureList || []
          const schoolList = res.data.schoolList || []
          let title = '成功'
          let type = 'success'
          let duration = 2000
          if (failureList.length > 0 || schoolList.length > 0) {
            title = '警告'
            type = 'warning'
            duration = 0
          }
          that.$notify({
            title: title,
            duration: duration,
            message: res.data.msg || '',
            dangerouslyUseHTMLString: true,
            
            type: type
          })
          that.fullscreenLoading = false
          that.fileList = []
          that.fileName = ''
          that.file = ''
          that.xlsxJsonData = []
        })
        .catch(() => {
          that.fullscreenLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  background: #fff;
  text-align: center;
  color: #333;
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #CCCCCC;
    font-size: 15px;
    font-weight: 500;
    .header-button{
      display: flex;
      align-items: center;
    }
    span {
      border-left: 2px solid;
      padding: 0 8px;
      box-sizing: border-box;
    }
    a {
      padding-right: 10px;
      color:#333;
    }
  }
  .upload-demo{
    padding: 8px 0;
  }
  .tips {
    width: 100%;
    background: rgba(245, 98, 0, 0.05);
    border-radius: 4px;
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    line-height: 33px;
    padding: 8px;
    border: 1px solid #FA6401;
    margin-top: 15px;
  }

  .steps {
    padding-top: 12px;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
  }

  .pic {
    margin-top: 20px;
  }

  .stepsText {
    span {
      text-align: left;
      font-weight: bold;
      font-size: 10px;
    }
  }

  .prompt {
    margin-top: 20px;
  }

  .pic {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .stepsTwo {
    padding-top: 20px;
    text-align: left;
    font-weight: bold;
    font-size: 20px;

    .upload-demo {
      .uploadIcon {
        display: inline-block;
        width: 61px;
        height: 51px;
        margin-top: 40px;
        justify-content: center;
        background-size: 61px 51px;
        background-image: url("./../../assets/detection/image/update@2x.png");
      }
    }

    .el-upload__tip {
      i {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-size: 10px 10px;
        background-image: url("./../../assets/detection/image/附件@2x.png");

      }
    }

  }

  .footer {
    display: flex;
    margin: 10px 0 10px 0;
  }
  .el-image-viewer__wrapper .el-image-viewer__canvas .el-image-viewer__img{
    max-width: unset !important;
    max-height: unset !important;
  }
}
</style>
